@use '../_variables' as v;
@use '../addons/bulma/_bulma-basic' as b;

.button {
  height: auto;
  overflow: hidden;
  border: 1px solid v.$blue;
  background: transparent;
  color: v.$blue;
  &:hover {
    text-decoration: none !important;
    background: v.$blue;
    color: v.$white;

  }
}

.info-trigger {
  cursor: pointer;
  background: v.$white;
  border: 1px solid v.$blue;
  border-radius: 6px;
  padding: 10px 14px;
  color: v.$blue;
  i {
    display: block;
  }
  span {
    display: block;
    @media only screen and (max-width: 370px) {
      font-size: 11px;
    }
  }
}

@use '../_variables' as v;
@use '../addons/bulma/_bulma-basic' as b;

@forward './_navigation';

header {
    box-shadow: 2px 2px 40px rgba(v.$yellow, 0.2);
    padding: 15px 0;
    position: sticky;
    top: 0;
    z-index: 3;
    background: v.$blue;
    height: 70px;

    img {
        filter: brightness(0) invert(1);

    }
}
// Colors
$black-lighter: #282828 !default
$black-light: #181818 !default
$black: #121212 !default
$black-bis: #000000 !default
//$black-ter:    hsl(0, 0%, 14%) !default

//$grey-darker:  hsl(0, 0%, 21%) !default
//$grey-dark:    #828282 !default
$grey: #e9e9e9 !default
$grey-light: #fefefe !default
//$grey-lighter: #d8d8d8 !default
//$grey-lightest: hsl(0, 0%, 93%) !default

//$white-ter:    hsl(0, 0%, 96%) !default
//$white-bis:    hsl(0, 0%, 98%) !default
$white: hsl(0, 0%, 100%) !default

$yellow: #E6B350 !default
$green: #1fc142 !default
//$turquoise:    hsl(171, 100%, 41%) !default
//$cyan:         #1ccfc9 !default
$blue: #176079 !default

//$purple:       hsl(271, 100%, 71%) !default
$red: #E91D24 !default
$orange: #f48507 !default

$primary: $green !default
$info: $blue !default
$success: $green !default
$warning: $yellow !default
$danger: $red !default

$light: $white !default
$dark: $black !default

// General colors
$background: $blue !default
$border: $blue !default
$border-hover: $blue !default

// Text colors
$text: $yellow !default
$text-light: $yellow !default
$text-strong: $yellow !default

// Link colors
$link: $yellow !default
$link-visited: $yellow !default

$link-hover: $yellow !default
$link-hover-border: $yellow !default

$link-focus: $yellow !default
$link-focus-border: $yellow !default

$link-active: $yellow !default
$link-active-border: $yellow !default

// Typography
$family-sans-serif: 'PFSquareSansPro', sans-serif !default
$body-font-family: $family-sans-serif !default
$header-font-family: 'PFSquareSansPro', sans-serif !default

$size-1: 4.375rem !default // 70px
$size-2: 3.8125rem !default // 61px
$size-3: 3rem !default // 48px
$size-4: 1.75rem !default // 28px
$size-5: 1.5625rem !default // 25px
$size-6: 1.25rem !default // 20px
$size-7: 1.125rem !default // 18px
$size-8: 1rem !default // 16px
$size-9: 0.875rem !default // 14px
$size-10: 0.8125rem !default // 13px
$size-11: 0.75rem !default // 12px

$size-small: $size-11 !default
$size-normal: 16px !default
$size-medium: $size-7 !default
$size-large: $size-3 !default

$weight-light: 300 !default
$weight-normal: 400 !default
$weight-medium: 500 !default
$weight-semibold: 600 !default
$weight-bold: 700 !default

$body-background-color: $white !default
$body-size: $size-normal !default

$body-color: $text !default
$body-font-size: $size-8 !default
$body-weight: $weight-normal !default
$body-line-height: 1.4 !default

$small-font-size: $size-7 !default

$hr-background-color: $grey !default
$hr-height: 3px !default
$hr-margin: 32px 0 !default

// Responsiveness
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 1026px !default
// 960px container + 4rem
$desktop: 1280px + (2 * $gap) !default
// 1152px container + 4rem
$widescreen: 1280px + (2 * $gap) !default
$widescreen-enabled: true !default
// 1344px container + 4rem
$fullhd: 1280px + (2 * $gap) !default
$fullhd-enabled: true !default

// Miscellaneous
$radius-small: 2px !default
$radius: 4px !default
$radius-large: 6px !default

@use '../_variables' as v;
@use '../addons/bulma/_bulma-basic' as b;

.delete {
    background-color: transparent;
    max-height: 36px;
    max-width: 36px;
    min-height: 36px;
    min-width: 36px;
    border: none;
    &::before, &::after {
      display: none;
    }
  
    &:hover, &:focus {
      background-color: transparent;
    }
  }


  .modal {
    justify-content: flex-start;
  }
  
  .modal-card {
    background: v.$blue;
    text-align: center;
    height: 100vh;
  
    .button-container {
      text-align: right;
      padding: 16px;
    }
  }
  
  .follow-title, .evaluation-title, .contact-title {
    padding-top: 30px;
    position: relative;
    margin-bottom: 10px;
    color: v.$white;
    font-size: v.$size-4;
    font-weight: 500;
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: v.$white;
    }
  }
  
  .contact-title {
    margin-bottom: 10px;
    font-size: v.$size-4;
    @media only screen and (max-width: 374px) {
      font-size: v.$size-5;
  }
  
  }
  
  .social-link, .map-link, .phone {
    font-size: v.$size-5;
  }
  
  .map-link, .phone {
    span {
      text-decoration: underline;
    }
  }
  
  .phone {
    display: block;
    margin-top: 10px;
  }
  
  .follow-title, .evaluation-title {
    margin-top: 40px;
  }
  
  .tripadvisor {
    span {
      margin-left: 5px;
    }
  }
@use '../_variables' as v;
@use '../addons/bulma/_bulma-basic' as b;

footer {
  margin-top: 20px;
  padding: 15px 0;
  position: relative;

  &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 32px);
      height: 1px;
      background: v.$blue;
  }


  p {
      color: v.$white;
      font-weight: bold;
      font-size: 11px;
      text-align: center;
  }
}
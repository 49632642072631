@use '../_variables' as v;
@use '../addons/bulma/_bulma-basic' as b;

@use '../addons/bulma/_bulma-essentials';

@forward './_header';
@forward './_footer';
@forward '../molecules/_modal';
@forward '../atoms/_buttons';
@forward '../molecules/_dropdown';

body {
    position: relative;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: url('../dist/img/bg-menta.png') repeat;
        opacity: 0.96;
    }
}

.is-visible-tablet {
    display: none;
    @media only screen and (min-width: 650px) {
        display: inline-block;
    }
}

.site-title {
    margin-top: 10px;
    color: v.$white;
    font-size: v.$size-2;
    text-align: center; 
    @media only screen and (max-width: 374px) {
        font-size: v.$size-3;
    }
}

.site-subtitle {
    margin-bottom: 20px;
    color: v.$white;
    font-size: v.$size-5;
    text-align: center; 
}



.menu-cards-wrapper {
    .is-6 {
        &:nth-child(1), &:nth-child(4) {
            padding-right: 12px;
        }
    }
    .is-6 {
        &:nth-child(2), &:nth-child(5) {
            padding-left: 12px;
        }
    }
}

.menu-card {
    position: relative;
    margin-bottom: 25px;
    padding: 20px 5px;
    background: v.$white;
    border: 1px solid v.$blue;
    border-radius: 6px;
    text-align: center;
}

.menu-card-link {
    color: v.$blue;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
    i, span {
        display: block;
    }
    span {
        color: v.$blue;
        font-size: v.$size-7;
        margin-top: 10px;
        font-weight: 500;
        @media only screen and (max-width: 374px) {
            font-size: v.$size-8;
        }
    }

    &:hover {
        color: v.$blue;
    }
}

.info {
    .follow-title, .evaluation-title, .contact-title {
        margin-top: 20px;
        padding-top: 20px;
        color: v.$white;
        font-size: v.$size-5;
    }

    .social-link, .map-link, .phone {
        font-size: 20px;
        font-weight: 500;
    }

    .social-link {
        background: v.$blue;
        &.tripadvisor {
            background: v.$white;
        }
    }
}

.tripadvisor {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px 14px;
}

.menu-info {
    position: relative;
    padding-top: 20px;
    
    &:before {
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        background: v.$blue;
    }

    p {
        font-size: v.$size-8;
    }
}
@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-ExtraBlackItalic.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-ExtraBlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-BoldItalic.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-Bold.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src: url('fonts/PFSquareSansPro-ExtraBlack.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-ExtraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src: url('fonts/PFSquareSansPro-Light.woff2') format('woff2'),
       url('fonts/PFSquareSansPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src: url('fonts/PFSquareSansPro-Italic.woff2') format('woff2'),
       url('fonts/PFSquareSansPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-LightItalic.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-Regular.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-Medium.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-MediumItalic.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-Thin.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFSquareSansPro';
  src:url('fonts/PFSquareSansPro-ThinItalic.woff2') format('woff2'),
      url('fonts/PFSquareSansPro-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}


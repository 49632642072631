@use '../_variables' as v;
@use '../addons/bulma/_bulma-basic' as b;

h1, h2, h3, h4, h5, h6 {
  font-family: v.$header-font-family;
}

.content {

  p, li {
    @include b.mobile {
        font-size: v.$size-7;
    }
  }

  a {
    text-decoration: underline;
  }

  li {
    + li {
      margin-top: 8px;
    }
  }

  blockquote {
    margin: 20px 0;
    padding: 0;
    border-top: 1px solid v.$yellow;
    border-bottom: 1px solid v.$yellow;
    border-left: 0;
    p {
      max-width: 710px;
      margin: 0 auto;
      padding: 20px 0;
      color: v.$white;
      font-size: v.$size-7;
      text-align: center;
      font-style: italic;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

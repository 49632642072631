@use '../_variables' as v;

.navbar-burger {
  color: v.$white;
  &:hover {
    color: v.$white;
    background: transparent;
  }

  span {
    width: 20px;
    height: 2px;
    left: calc(50% - 12px);
    &:nth-child(1) {
      top: calc(50% - 10px);
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
      top: calc(50% + 8px);
    }
  }

  &.is-active {
    span {
      &:nth-child(1) {
        width: 26px;
        height: 2px;
        transform: translateY(10px) rotate(45deg);
      }
      &:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
        width: 26px;
      }
    }
  }
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.img-container {
  position: relative;
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

img {
  display: inline-block;
  vertical-align: middle;
}

.video-container {
  position: relative;
}

video {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}